<template>
  <div class="wrap">
    <div class="pur-top-right-icon">
      <i
        class="iconfont icon-xiadan"
        :class="'i-green'"
        v-if="!CANCEL || this.statusObj.UNCHKED"
      />
      <span
        :class="[currentIndex > 1 ? 'green' : 'gray']"
        v-if="!CANCEL || this.statusObj.UNCHKED"
      ></span>
      <i
        v-if="(!CANCEL || this.statusObj.REVIEWCOMPLETED)"
        class="iconfont icon-dingdanpingshen"
        :class="[currentIndex >= 2 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 2 ? 'green' : 'gray']"
        v-if="(!CANCEL || this.statusObj.REVIEWCOMPLETED)"
      ></span>
      <i
        v-if="!CANCEL || this.statusObj.WAITDELIVER"
        class="iconfont icon-jihuafahuo"
        :class="[currentIndex >= 3 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 3 ? 'green' : 'gray']"
        v-if="!CANCEL || this.statusObj.WAITDELIVER"
      ></span>
      <i
        v-if="!CANCEL || this.statusObj.PARTCHECKED"
        class="iconfont icon-fahuozhong"
        :class="[currentIndex >= 4 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 4 ? 'green' : 'gray']"
        v-if="!CANCEL || this.statusObj.PARTCHECKED"
      ></span>
      <i
        v-if="!CANCEL || this.statusObj.CUSTSIGIN|| this.statusObj.CHECKED"
        class="iconfont icon-fahuowancheng-1"
        :class="[currentIndex >= 5 ? 'i-green' : 'i-gray']"
      />
      <i
        v-if="CANCEL"
        class="iconfont icon-quxiao i-gray"
      />
    </div>
    <div class="pur-top-right-txt">
      <div v-if="!CANCEL || this.statusObj.UNCHKED">
        <p style="padding-right:4px">下单</p>
        <p v-if="currentIndex >= 1">{{  orderdetail.orderHeader&&formatDate(orderdetail.orderHeader.orderData) }}</p>
      </div>
      <div v-if="(!CANCEL || this.statusObj.REVIEWCOMPLETED)">
        <p>订单评审</p>
        <p v-if="currentIndex >= 2">{{ this.statusObj.REVIEWCOMPLETED||this.statusObj.HEADCHECK||this.statusObj.BRANCHCHECK }}</p>
      </div>
      <div v-if="!CANCEL || this.statusObj.WAITDELIVER">
        <p>计划发货</p>
        <p v-if="currentIndex >= 3">{{ this.statusObj.WAITDELIVER }}</p>
      </div>
      <div v-if="!CANCEL || this.statusObj.PARTCHECKED">
        <p>发货中</p>
        <p v-if="currentIndex >= 4">{{ this.statusObj.PARTCHECKED }}</p>
      </div>
      <div v-if="!CANCEL || this.statusObj.CUSTSIGIN">
        <p>收货完成</p>
        <p v-if="currentIndex >= 5">{{ this.statusObj.CUSTSIGIN||this.statusObj.CHECKED }}</p>
      </div>
      <div v-if="CANCEL">
        <p>取消</p>
        <p>{{ this.statusObj.CANCEL }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("zh-cn");
export default {
  name: "Step",
  props: {
    orderdetail: {
      required: true,
      type: Object
    }
  },
  watch: {
    "orderdetail.statusInfos": {
      handler: function() {
        this.statusObj = {};
        if (this.orderdetail && this.orderdetail.statusInfos) {
          this.orderdetail.statusInfos.forEach(item => {
            this.statusObj[item.status] = item.date;
          });
        }
        if (this.statusObj.CANCEL) {
          this.CANCEL = true;
        } else {
          this.CANCEL = false;
        }
      },
      immediate: true
    }
  },
  computed: {
    currentIndex: function() {
      let step = 1;
      if (this.orderdetail && this.orderdetail.statusInfos) {
        if (
          this.statusObj.REVIEWCOMPLETED ||
          this.orderdetail.orderHeader.orderTypeCode == "routine"
        ) {
          step = 2;
        }
        if (this.statusObj.WAITDELIVER) {
          step = 3;
        }

        if (this.statusObj.PARTCHECKED) {
          step = 4;
        }

        if (this.statusObj.CUSTSIGIN||this.statusObj.CHECKED) {
          step = 5;
        }
        return step;
      } else {
        return step;
      }
    }
  },
  data() {
    return {
      statusObj: {},
      CANCEL: false
    };
  },
  methods:{
    formatDate(date){
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }
  }
};
</script>

<style scoped lang="less">
.wrap {
  width: 930px;
  height: 139px;
  background: #fff;

  .pur-top-right-icon {
    text-align: left;
    padding-left: 70px;

    .iconfont {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: 21px;
      position: relative;
      top: 8px;
      text-align: center;
    }

    .i-green {
      color: #00aaa6;
      border: 1.5px solid #00aaa6;
    }

    .i-gray {
      color: #aaa;
      border: 1.5px solid #aaa;
    }

    img {
      width: 36px;
      height: 36px;
    }

    span {
      width: 142px;
      margin: 18px 6px 0;
    }

    .green {
      border-bottom: 1px dashed #00aaa6;
    }

    .gray {
      border-bottom: 1px dashed #cccccc;
    }
  }

  .pur-top-right-txt {
    margin: 12px 0 0 28px;
    // :nth-child(1){
    // }
    :nth-child(5) {
      margin-right: 0px;
    }

    div {
      float: left;
      width: 120px;
      margin: 0 70px 0 0;

      p {
        font-size: 12px;
        font-weight: 500;
        color: #262626;
        line-height: 12px;
        margin-bottom: 7px;
      }
    }
  }
}
</style>
